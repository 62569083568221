import { mapValues } from 'remeda';
import { EnquiryIntentCheckboxes } from '@bridebook/toolbox/src';
import {
  EnquiryDetailedIntents,
  EnquiryIntentType,
  EnquiryIntents,
} from '@bridebook/toolbox/src/types';
import { getI18n } from 'lib/i18n/getI18n';

const getEnquiryIntentLabels = (): Record<keyof EnquiryIntentType, string> => {
  const i18n = getI18n();
  return {
    info: i18n.t('enquiries:intent.info'),
    brochure: i18n.t('enquiries:intent.brochure'),
    pricing: i18n.t('enquiries:intent.pricing'),
    dates: i18n.t('enquiries:intent.dates'),
    availability: i18n.t('enquiries:intent.availability'),
    quote: i18n.t('enquiries:intent.quote'),
    showround: i18n.t('enquiries:intent.showround'),
    videotour: i18n.t('enquiries:intent.videotour'),
    other: i18n.t('enquiries:intent.other'),
    /*
    LIVE-21330: this is not supposed to be passed along to the vendor nor included in the message
    but if the requestFastResponse property is missing or the types are adjusted to function without it
    there is a cascading failure that eventually causes issues in enquiry modal's checkboxes
     */
    requestFastResponse: '',
  };
};

/**
 * Get detailed intents based on the provided intents.
 * If no intents are provided, default values are used.
 */
export const getDetailedIntents = (intents?: EnquiryIntents): EnquiryDetailedIntents => {
  const defaultValues: EnquiryDetailedIntents =
    mapIntentsToDetailedIntents(EnquiryIntentCheckboxes);
  const detailedIntents = intents ? mapIntentsToDetailedIntents(intents) : {};

  return { ...defaultValues, ...detailedIntents };
};

const mapIntentsToDetailedIntents = (intent: EnquiryIntents): EnquiryDetailedIntents => {
  const labels = getEnquiryIntentLabels();
  return mapValues(intent, (checked, key) => ({
    id: key,
    label: labels[key],
    checked,
  }));
};
