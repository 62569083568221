import { isNil } from 'ramda';
import React, { ReactElement, memo, useEffect, useRef, useState } from 'react';
import type { FelaCSS } from 'src/components/fela/flowtypes';
import { slugify } from '@bridebook/toolbox/src';
import Box from '../../../components/fela/Box';
import { TSpacingValue } from '../../../themes/types';
import componentStyles from './tabs-rounded.style';

export interface ITabsItem {
  title: string | ReactElement;
  disabled?: boolean;
  id: string;
}

interface IProps {
  tabs: ITabsItem[];
  /**
   * Callback when a tab is clicked
   */
  onClick: (item: ITabsItem, index: number) => void;
  /**
   * Control the active tab from outside
   */
  activeTabIndex?: number;
  /**
   * Sets the first active tab on mount
   */
  initialTabIndex?: number;
  /**
   * Size of the tabs
   */
  size?: 'default' | 'small';
  /**
   * Expand tabs to fill the width of the container.
   * If true, then tabs have equal widths.
   * If false, then tabs have variable width related to their content.
   */
  fullWidth?: boolean;
  /**
   * Padding of the tabs, only works if fullWidth is false, otherwise tabs have equal width
   */
  tabPadding?: TSpacingValue;
}

const TabsRoundedComp = ({
  activeTabIndex,
  initialTabIndex = 0,
  tabs,
  fullWidth = false,
  tabPadding = 6,
  onClick,
  size = 'default',
}: IProps) => {
  const [activeIndex, setActiveIndex] = useState(activeTabIndex || initialTabIndex);
  const [sliderStyle, setSliderStyle] = useState<FelaCSS>({});
  const tabsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (!isNil(activeTabIndex) && activeTabIndex !== activeIndex) {
      setActiveIndex(activeTabIndex);
    }
  }, [activeIndex, activeTabIndex]);

  useEffect(() => {
    const activeTabElement = tabsRef.current[activeIndex];
    if (activeTabElement) {
      const rect = activeTabElement.getBoundingClientRect();
      // Calculate the new position
      setSliderStyle({
        width: `${rect.width}px`,
        transform: `translateX(${activeTabElement.offsetLeft}px)`,
        transition:
          'transform 0.5s cubic-bezier(0.34, 1.28, 0.64, 1), width 0.3s cubic-bezier(0.34, 1.28, 0.64, 1)',
      });
    }
  }, [activeIndex]);

  const _onTabClick: IProps['onClick'] = (item, index) => {
    if (item.disabled) return;
    isNil(activeTabIndex) && setActiveIndex(index);
    onClick(item, index);
  };

  const styles = componentStyles({ tabsAmount: tabs.length, fullWidth, size, tabPadding });

  return (
    <Box style={styles.wrapper}>
      <Box id="tabs-slider" style={{ ...styles.slider, ...sliderStyle }} />
      {tabs.map((item, index) => (
        <Box
          setRef={(el) => (tabsRef.current[index] = el)}
          key={`tabs-tab-item-${slugify(item.id || item.title.toString())}`}
          as="a"
          id={`tab-${index}`}
          style={styles.tab({
            active: index === activeIndex,
            disabled: Boolean(item.disabled),
          })}
          onClick={() => _onTabClick(item, index)}>
          {item.title}
        </Box>
      ))}
    </Box>
  );
};

export const TabsRounded = memo(TabsRoundedComp);
