import Router from 'next/router';
import { equals, values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, interval, of } from 'rxjs';
import {
  catchError,
  concat,
  debounceTime,
  groupBy,
  map,
  mergeMap,
  take,
  tap,
  throttleTime,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ValidationError, mapDateFromUI } from '@bridebook/toolbox/src';
import { getConversationId } from '@bridebook/toolbox/src/inbox/api/talkjs/utils';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { EnquiryIntentType, IUISupplier } from '@bridebook/toolbox/src/types';
import { TWithSupplierData } from 'app-shared/lib/supplier/supplier-types';
import { getVariant_ForcePhoneNumber } from 'lib/ab-tests/tests/global/LIVE19141_ForceIEAndFRCouplesToEnterPhoneNumberBeforeSendingEnquiries';
import { addConversation } from 'lib/api/conversations/fetchConverstationsList';
import { appError } from 'lib/app/actions';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import {
  cancelDatepickerEdit,
  resetDatepickerDate,
  startDatepickerDateEdit,
} from 'lib/datepicker/actions';
import { EnquiriesActionTypes, ISendEnquiryAction } from 'lib/enquiries/action-types';
import { sendInboxMessageForEnquiry } from 'lib/enquiries/utils/send-inbox-message-for-enquiry';
import { env } from 'lib/env';
import { getI18n } from 'lib/i18n/getI18n';
import { getCountryCodeWithFallback, getIsUK } from 'lib/i18n/selectors';
import { saveToShortlist } from 'lib/shortlist/actions';
import { getBookedVenuesList, getNonCustomSuppliersToContactList } from 'lib/shortlist/selectors';
import { buildUISupplier } from 'lib/shortlist/utils';
import { SupplierActions } from 'lib/supplier/actions-types';
import { isSupplierVenue } from 'lib/supplier/utils';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import {
  Action,
  EnquiryFormFieldsType,
  EnquiryFormInfoProps,
  IApplicationState,
  IDeps,
  IEpicDeps,
  IProfile,
  IUserSerialized,
} from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { updateUserEmail, updateUserPhone } from 'lib/users/actions';
import { toUrlQuery } from 'lib/utils';
import { getAsPathNormalized } from 'lib/utils/url';
import { updateWeddingField, weddingProfileSaveDate } from 'lib/weddings/actions';
import { isBudgetMatch } from '../supplier/utils/budget-match';
import { WeddingActionTypes } from '../weddings/action-types';
import {
  confirmedEnquiryDetailsAnalytics,
  failedToConfirmDetailsAnalytics,
  failedToSendEnquiryAnalytics,
  sendEnquirySuccessAnalytics,
} from './analytics/actions';
import SendEnquiryError from './errors/send-enquiry-error';
import {
  EnquiryFormContextType,
  IEnquiryCountAnalytics,
  IEnquiryDate,
  IEnquiryFormToggleArgs,
} from './types';
import createEnquiry from './utils/create-enquiry';
import { getEnquiryFormContext, getEnquiryFormContextMessage } from './utils/get-enquiry-context';
import { filterOutUrls, isSpam } from './utils/spam-check';
import validateEnquiryForm from './utils/validate-enquiry-form';
import validateEnquiryIntent from './utils/validate-enquiry-intent';

export const setEnquirySupplier = ({ supplier }: { supplier: IUISupplier }) => ({
  type: EnquiriesActionTypes.SET_ENQUIRY_SUPPLIER,
  payload: supplier,
});

export type ICheckEnquiryDetailEvent = {
  target: {
    name: keyof EnquiryIntentType;
    checked: boolean;
  };
};
export const checkEnquiryDetail = ({ target: { checked, name } }: ICheckEnquiryDetailEvent) => ({
  type: EnquiriesActionTypes.CHECK_ENQUIRY_DETAIL,
  payload: { id: name, checked },
});

export const toggleEnquiryMessage = () => ({
  type: EnquiriesActionTypes.TOGGLE_ENQUIRY_MESSAGE,
  payload: true,
});

export const resetEnquiryForm =
  () =>
  ({ getState }: IDeps) => {
    const {
      weddings: { profile },
      users: { user },
    } = getState();

    return {
      type: EnquiriesActionTypes.RESET_ENQUIRY_FORM,
      payload: { profile, user },
    };
  };

export const enquiryEditToggleDatepicker =
  (newState: boolean, track: boolean = true) =>
  ({ dispatch, getState }: IDeps) => {
    const { instances } = getState().datepicker;
    const {
      enquiries: {
        enquiryForm: {
          error,
          fields: { weddingDateDatePicker },
        },
      },
    } = getState();

    /** Reset error state when set, similar to form fields */
    if (error) {
      dispatch(dismissEnquiryFormError);
    }

    // TIMEOUT TO WAIT FOR ANAMATION TO FINISH
    setTimeout(() => {
      if (!newState && instances.weddingDate) {
        dispatch(cancelDatepickerEdit('weddingDate', track));
        dispatch(resetDatepickerDate('weddingDate'));
      }

      if (newState) {
        dispatch(startDatepickerDateEdit('weddingDate', weddingDateDatePicker));
      }
    }, 300);

    return {
      type: EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE_DATEPICKER,
      payload: newState,
    };
  };

export const enquiryFormToggleEdit =
  (newState: boolean) =>
  ({ dispatch }: IDeps) => {
    if (!newState) {
      dispatch(enquiryEditToggleDatepicker(false, false));
    }

    return {
      type: EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE_EDIT,
      payload: newState,
    };
  };

export const onSupplierEnquiryInteractAnalytics = (
  type: string,
  infoProps: EnquiryFormInfoProps = {},
) => ({
  type: EnquiriesActionTypes.ON_SUPPLIER_ENQUIRY_INTERACT_ANALYTICS,
  payload: { type, ...infoProps },
});

export const enquiryFormToggle =
  ({
    show = true,
    infoProps = {},
    track = true,
    supplier = null,
    resetEnquiry = true,
  }: IEnquiryFormToggleArgs) =>
  (deps: IDeps) => {
    const { getState, dispatch } = deps;
    const {
      enquiries: { enquirySupplier },
    } = getState();

    // Resetting enquiry data here causes the analytics sent to use default contact intent data
    // instead of the one provided by the user when closing the form.
    if (resetEnquiry) {
      dispatch(resetEnquiryForm());
    }

    track && dispatch(dismissEnquiryFormError());
    !show && dispatch(enquiryFormToggleEdit(false));

    return {
      type: EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE,
      payload: {
        show,
        supplier: enquirySupplier || supplier,
        infoProps,
        track,
      },
    };
  };

export const enquiryRequiredDataToggle =
  ({ show = true }) =>
  (deps: IDeps) => {
    const { getState, dispatch } = deps;
    const {
      enquiries: { enquirySupplier: supplier },
    } = getState();

    dispatch(resetEnquiryForm());

    return {
      type: EnquiriesActionTypes.ENQUIRY_REQUIRED_DATA_TOGGLE,
      payload: { show, supplier },
    };
  };

const dismissEnquiryFormError = () => ({
  type: EnquiriesActionTypes.DISMISS_ENQUIRY_FORM_ERROR,
});

export const setEnquiryFormField =
  <P>(name: string, value: P) =>
  ({ dispatch, getState }: IDeps) => {
    const {
      enquiries: {
        enquiryForm: { error },
      },
    } = getState();

    if (error) dispatch(dismissEnquiryFormError);

    return {
      type: EnquiriesActionTypes.SET_ENQUIRY_FORM_FIELD,
      payload: { name, value },
    };
  };

export const setLoggedOutEnquiryTriggered = () => ({
  type: EnquiriesActionTypes.SET_LOGGED_OUT_ENQUIRY_TRIGGERED,
});

export const saveEnquiryDatepickerDate =
  () =>
  ({ dispatch, getState }: IDeps) => {
    const { weddingDate } = getState().datepicker.instances;
    if (weddingDate) {
      dispatch(setEnquiryFormField('weddingDateDatePicker', weddingDate.datePickerDate));
      dispatch(setEnquiryFormField('weddingDate', mapDateFromUI(weddingDate.datePickerDate)));
    }
    dispatch(weddingProfileSaveDate('weddingDate'));

    return {
      type: EnquiriesActionTypes.SAVE_ENQUIRY_DATEPICKER_DATE,
    };
  };

export const sendEnquirySuccess =
  (supplier: IUISupplier, multipleEnquiry?: boolean, noConfirmation?: boolean) =>
  ({ getState, dispatch }: IDeps) => {
    const state = getState();
    const {
      shortlist: { list },
      enquiries: {
        userEnquiryAnalyticsMethod,
        enquiryForm: { context },
      },
      users: { user },
      weddings: {
        profile: { id: weddingId },
      },
    } = state;
    const { query } = Router;
    const { enquiryConfirmation } = query;
    dispatch(fetchEnquiryDates());

    const isLoggedIn = getAuthStatus({ user: getState().users.user });

    const { id, type } = supplier;
    const isShortlisted = list && Boolean(list[id]);
    if (!isShortlisted) {
      dispatch(saveToShortlist({ ...supplier, enquired: true }, 'onEnquirySend', undefined, false));
      dispatch(dismissEnquiryFormError());
    } else {
      Weddings._.getById(weddingId).Suppliers.getById(id).update({ enquired: true });
    }

    const isVenue = type === 'venue' || (Array.isArray(type) && type.includes('venue'));
    const isPhotographer = type === 'photo' || (Array.isArray(type) && type.includes('photo'));
    const bookedVenuesList = getBookedVenuesList(state);

    // Add conversation to react-query cache
    addConversation(getConversationId({ supplierId: id, weddingId }));

    const isUK = getIsUK(state);
    if (
      isUK &&
      isVenue &&
      bookedVenuesList.length < 1 &&
      !enquiryConfirmation &&
      isLoggedIn &&
      !multipleEnquiry &&
      !noConfirmation
    ) {
      dispatch(
        toggleEnquiryConfirmation({
          show: true,
          enquirySupplierId: supplier.publicId,
        }),
      );
    }
    // https://bridebook.atlassian.net/browse/LIVE-15607 enquiry confirmation is only active when book photographer context is active
    else if (
      isPhotographer &&
      isUK &&
      context.contextName === 'linkedSupplier_weddingConfirmationPopup'
    ) {
      dispatch(
        toggleEnquiryConfirmation({
          show: true,
          enquirySupplierId: supplier.publicId,
        }),
      );
    } else {
      const i18n = getI18n();
      dispatch(enquiryFormToggle({ show: false, track: false }));
      const messageEmail = user && user.contacts ? user.contacts.email : 'your email address';
      const brochureEmail = user && user.contacts ? user.contacts.email : 'you now';

      const brochureSentCopy = i18n.t('enquiries:enquirySent.brochure', { brochureEmail });

      const messageSentCopy = i18n.t('enquiries:enquirySent.message', {
        count: multipleEnquiry ? 2 : 1,
      });

      const repliesCopy = i18n.t('enquiries:enquirySent.repliesInbox', { messageEmail });

      const message =
        userEnquiryAnalyticsMethod === 'supplierProfile_topButtonDownloadable'
          ? brochureSentCopy
          : `${messageSentCopy} ${repliesCopy}`;

      dispatch(toggleSnackbar('success', message));
    }

    return {
      type: EnquiriesActionTypes.SEND_ENQUIRY_SUCCESS,
      payload: supplier,
    };
  };

const sendEnquiryError = (error: Error, supplier?: IUISupplier) => (deps: IDeps) => {
  const { dispatch } = deps;

  dispatch(failedToSendEnquiryAnalytics(error, supplier));

  if (error instanceof ValidationError || error instanceof SendEnquiryError) {
    const message = 'messageI18n' in error ? error.messageI18n || error.message : error.message;

    dispatch(toggleSnackbar('alert', message));
  }

  /*
   * if error is ValidationError then the error comes after validating fields,
   * Otherwise it comes from analytics lambda call
   */
  if (error instanceof ValidationError) {
    return validateEnquiryFormError(error);
  }

  return {
    type: EnquiriesActionTypes.SEND_ENQUIRY_ERROR,
    payload: error,
  };
};

export const validateEnquiryFormError = (error: ValidationError) => ({
  type: EnquiriesActionTypes.VALIDATE_ENQUIRY_FORM_ERROR,
  payload: error,
});

export const sendEnquiry =
  (
    {
      supplier,
      captchaToken,
      contactLocation,
      enquiryDetails,
      noValidation,
    }: {
      supplier: IUISupplier;
      captchaToken: string;
      contactLocation?: string;
      enquiryDetails?: EnquiryFormFieldsType;
      noValidation?: boolean;
    },
    noConfirmation?: boolean,
    isFromExternalPage?: boolean,
  ) =>
  ({ dispatch }: IDeps) => {
    dispatch(dismissEnquiryFormError());

    return {
      type: EnquiriesActionTypes.SEND_ENQUIRY_START,
      payload: {
        supplier,
        contactLocation,
        captchaToken,
        noConfirmation,
        isFromExternalPage,
        enquiryDetails,
        noValidation,
      },
    };
  };

export const sendEnquiryEpic = (
  action$: Observable<ISendEnquiryAction>,
  { state$, cordovaTracker }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.SEND_ENQUIRY_START),
    groupBy((action) => action.payload.supplier.id),
    mergeMap((action$) =>
      action$.pipe(
        withLatestFrom(state$),
        throttleTime(500),
        mergeMap(([action, state]) => {
          const { supplier, noConfirmation, isFromExternalPage, captchaToken, noValidation } =
            action.payload;
          const {
            enquiries: {
              enquiryForm: {
                fields: inputFields,
                context: { contextName },
              },
              enquirySupplier,
              multipleEnquiry,
              intent,
              enquiryDates,
              showUserLimit,
            },
            users: { user },
            weddings: {
              profile: {
                id: profileId,
                budget,
                // eslint-disable-next-line no-empty-pattern
                partners: [],
              },
            },
          } = state;
          // Anti-spam measure: filter out URLs inside the message

          const enquiryFields = action.payload.enquiryDetails
            ? action.payload.enquiryDetails
            : inputFields;

          const fields = { ...enquiryFields, message: filterOutUrls(enquiryFields.message) };
          const contactedSupplier = supplier || enquirySupplier;
          const supplierCategory = Array.isArray(supplier.type)
            ? supplier.type?.[0]
            : supplier.type || supplier.slug;
          const enquiriesCount = values(enquiryDates).filter(
            (e) => e.type === supplierCategory,
          ).length;
          const enquiryLimitReached = enquiriesCount >= env.ENQUIRIES_USER_LIMIT;

          if (enquiryLimitReached) {
            return showUserLimit ? of() : of(showEnquiriesUserLimitModal(true));
          }

          // and our custom "new enquiry" email won't be sent
          let inboxMessageSent = false;
          // Flag to avoid sending success analytics event
          let isSpammyMessage = false;

          const getPromise = async () => {
            if (!user) {
              throw new Error('no user');
            }
            if (!contactedSupplier) {
              throw new Error('no supplier');
            }

            // If the message contains blacklisted word silently return success without sending
            if (isSpam(fields.message)) {
              SentryMinimal().captureMessage(`[ENQUIRY_SPAM_DETECTED]`, {
                extra: fields,
              });
              isSpammyMessage = true;
              return of();
            }
            const { id: userId } = user;
            const needLocation =
              supplier.slug !== 'venue' &&
              supplier.type?.[0] !== 'venue' &&
              supplier.type !== 'venue';
            const countryCode = getCountryCodeWithFallback(state);
            const forcePhoneNumberVariant = getVariant_ForcePhoneNumber(state);

            if (!noValidation)
              await validateEnquiryForm({
                fields,
                isEdit: false,
                needLocation,
                countryCode,
                forcePhoneNumberVariant,
              });

            validateEnquiryIntent({
              intent,
              contextName,
            });

            const budgetMatch = isBudgetMatch(budget, contactedSupplier);
            let supplierWeddingId;

            try {
              const response = await createEnquiry({
                message: fields.message,
                userId,
                profileId,
                budgetMatch,
                intent,
                contactedSupplier,
                captchaToken,
              });

              if (!response?.id) {
                throw new SendEnquiryError();
              }

              supplierWeddingId = response?.id;
            } catch (error) {
              throw new SendEnquiryError();
            }

            /* ############################################################################
             *  SEND TALKJS INBOX MESSAGE
             * ######################################################################### */
            const result = await sendInboxMessageForEnquiry(
              {
                supplier: contactedSupplier,
                message: fields.message,
                id: supplierWeddingId,
              },
              state,
            );
            inboxMessageSent = result.inboxMessageSent;

            return supplierWeddingId;
          };

          return from(getPromise()).pipe(
            timeout(28000), // set timeout 28 seconds for api request
            debounceTime(500),
            tap(() => cordovaTracker.track(TrackingEvent.SendEnquiry, contactedSupplier)),
            mergeMap((enquiryId) => {
              const analyticsEvent = isSpammyMessage
                ? failedToSendEnquiryAnalytics(new Error('ENQUIRY_SPAM_DETECTED'), supplier)
                : sendEnquirySuccessAnalytics({
                    contactedSupplier,
                    contactLocation: action.payload.contactLocation,
                    enquiryId,
                    inboxMessageSent,
                    isFromExternalPage,
                  });

              return [
                analyticsEvent,
                sendEnquirySuccess(supplier, multipleEnquiry, noConfirmation),
              ];
            }),
            catchError((error) =>
              of(sendEnquiryError(error, supplier), appError({ error, feature: 'Enquiries' })),
            ),
          );
        }),
      ),
    ),
  );

export const closeEnquiryOnEnquiryLimitEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.SHOW_ENQUIRIES_USER_LIMIT_MODAL),
    mergeMap(() =>
      interval(100).pipe(
        take(1),
        mergeMap(() => of(enquiryFormToggle({ show: false }))),
      ),
    ),
  );

export const confirmEnquiryFormError = (error: ValidationError) => (deps: IDeps) => {
  const { dispatch, getState } = deps;
  const {
    enquiries: { enquiryFormEditState },
  } = getState();

  dispatch(failedToConfirmDetailsAnalytics(error));
  const i18n = getI18n();
  dispatch(
    toggleSnackbar(
      'alert',
      enquiryFormEditState
        ? error.messageI18n || error.message
        : i18n.t('enquiries:error.someDetailsMissing'),
    ),
  );

  return {
    type: EnquiriesActionTypes.CONFIRM_ENQUIRY_FORM_ERROR,
    payload: error,
  };
};

const confirmEnquiryFormSuccess =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch(confirmedEnquiryDetailsAnalytics('confirmation'));

    return { type: EnquiriesActionTypes.CONFIRM_ENQUIRY_FORM_SUCCESS };
  };

export const confirmEnquiryFormEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.CONFIRM_ENQUIRY_FORM_START),
    withLatestFrom(state$),
    mergeMap(([action, state]: [Action, IApplicationState]) => {
      const { supplier } = action.payload;
      const {
        enquiries: {
          enquiryForm: { fields },
        },
      } = state;
      const getPromise = async () => {
        const needLocation = supplier.slug !== 'venue' && supplier.type?.[0] !== 'venue';
        const countryCode = getCountryCodeWithFallback(state);
        const forcePhoneNumberVariant = getVariant_ForcePhoneNumber(state);
        await validateEnquiryForm({
          fields,
          isEdit: false,
          needLocation,
          countryCode,
          forcePhoneNumberVariant,
        });
      };

      return from(getPromise()).pipe(
        mergeMap(() => of(confirmEnquiryFormSuccess())),
        catchError((error) =>
          of(confirmEnquiryFormError(error), appError({ error, feature: 'Enquiries' })),
        ),
      );
    }),
  );

export const confirmEnquiryForm = ({ supplier }: { supplier: IUISupplier }) => ({
  type: EnquiriesActionTypes.CONFIRM_ENQUIRY_FORM_START,
  payload: { supplier },
});

const enquiriesGetUpdateActions = ({
  profile,
  user,
  fields,
}: {
  profile: IProfile;
  user: IUserSerialized | null;
  fields: EnquiryFormFieldsType;
}) => {
  const actions = [];

  /* weddingDate */
  if (fields.weddingDate && !equals(profile.date, fields.weddingDate)) {
    actions.push(of(updateWeddingField('date', { date: fields.weddingDate })));
  }

  /* partners */
  if (!equals(profile.partners, fields.partners)) {
    actions.push(of(updateWeddingField('partners', { partners: fields.partners })));
  }
  /* location */
  if (
    !equals(profile.location, fields.location) &&
    fields.location &&
    fields.location.name !== ''
  ) {
    actions.push(of(updateWeddingField('location', { location: fields.location })));
  }
  /* guestsInitialTarget */
  if (profile.guests.estimate !== fields.guestsInitialTarget) {
    actions.push(
      of(
        updateWeddingField('guests', {
          guests: { estimate: fields.guestsInitialTarget },
        }),
      ),
    );
  }
  /* email */
  if (user?.contacts?.email !== fields.email) {
    actions.push(of(updateUserEmail(fields.email)));
  }

  /* phone */
  if (user?.contacts?.phone !== fields.phone) {
    actions.push(of(updateUserPhone(fields.phone)));
  }

  return actions;
};

const saveEnquiryFieldsToDatabaseSuccess =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const state = getState();

    const { enquiryConfirmationScreenShown } = state.enquiries;
    const enabled = false; // here was feature toggle before

    if (enquiryConfirmationScreenShown && enabled) {
      dispatch(confirmedEnquiryDetailsAnalytics('edit'));
    }
    dispatch(enquiryFormToggleEdit(false));
    dispatch(toggleEnquiryConfirmationScreen(false));

    return {
      type: EnquiriesActionTypes.SAVE_ENQUIRY_FIELDS_TO_DATABASE_SUCCESS,
    };
  };

export const toggleEnquiryConfirmationScreen = (show: boolean) => ({
  type: EnquiriesActionTypes.TOGGLE_ENQUIRY_CONFIRMATION_SCREEN,
  payload: show,
});

export const saveEnquiryFieldsToDatabaseError =
  (error: ValidationError<keyof EnquiryFormFieldsType>) =>
  ({ dispatch, getState }: IDeps) => {
    const i18n = getI18n();
    const { fields } = getState().enquiries.enquiryForm;
    const name = error.prop;
    const value = fields[name];
    const isUserDetails = name === 'email' || name === 'phone';
    const errorMessage = isUserDetails
      ? error.messageI18n || error.message
      : i18n.t('common:error.fillOutMissingFields');

    if (isUserDetails) {
      dispatch({
        type: UserActionTypes.UPDATE_USER_DETAILS_ERROR,
        payload: { name, value, error: error.message },
      });
    } else {
      dispatch({
        type: WeddingActionTypes.UPDATE_WEDDING_FIELD_ERROR,
        payload: { name, value, error: error.message },
      });
    }

    dispatch(toggleSnackbar('alert', errorMessage));

    return {
      type: EnquiriesActionTypes.SAVE_ENQUIRY_FIELDS_TO_DATABASE_ERROR,
      payload: error,
    };
  };

export const saveEnquiryFieldsToDatabaseEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.SAVE_ENQUIRY_FIELDS_TO_DATABASE_START),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const {
        enquiries: {
          enquiryForm: { fields },
          enquirySupplier,
          enquiryConfirmationScreenShown,
          multipleEnquiry,
        },
        weddings: { profile },
        users: { user },
      } = state;
      const supplier = multipleEnquiry
        ? getNonCustomSuppliersToContactList(state)[0]
        : enquirySupplier;
      const actions = enquiriesGetUpdateActions({ profile, user, fields });
      const needLocation =
        supplier?.slug !== 'venue' && supplier?.type?.[0] !== 'venue' && supplier?.type !== 'venue';
      const countryCode = getCountryCodeWithFallback(state);
      const forcePhoneNumberVariant = getVariant_ForcePhoneNumber(state);

      const promise = validateEnquiryForm({
        fields,
        isEdit: true,
        needLocation,
        countryCode,
        forcePhoneNumberVariant,
      });

      return from(promise).pipe(
        map(saveEnquiryFieldsToDatabaseSuccess),
        concat(...actions),
        catchError((error) => {
          if (enquiryConfirmationScreenShown) {
            return of(confirmEnquiryFormError(error));
          }
          return of(
            saveEnquiryFieldsToDatabaseError(error),
            appError({ error, feature: 'Enquiries' }),
          );
        }),
      );
    }),
  );

export const saveEnquiryFieldsToDatabase = () => ({
  type: EnquiriesActionTypes.SAVE_ENQUIRY_FIELDS_TO_DATABASE_START,
});

export const enquiryClickedEdit =
  ({ isEdit = false, onPageEnquiry }: { isEdit: boolean; onPageEnquiry: boolean }) =>
  ({ getState, dispatch }: IDeps) => {
    const state = getState();

    const {
      supplier: {
        supplier: { data: stateSupplier },
      },
      enquiries: { enquirySupplier, infoProps },
    } = state;

    dispatch(onSupplierEnquiryInteractAnalytics('edit', infoProps));

    /* TODO: This could potentially cause a bug, as stateSupplier can be null. */
    const supplier = enquirySupplier || buildUISupplier(stateSupplier as ISupplier);

    /* If this is sidebar enquiry - then open it in popup first */
    if (onPageEnquiry) {
      dispatch(setEnquirySupplier({ supplier }));
      dispatch(enquiryFormToggle({ show: true }));
    }
    dispatch(enquiryFormToggleEdit(isEdit));

    return {
      type: EnquiriesActionTypes.ENQUIRY_CLICKED_EDIT,
    };
  };

export const setTriedToContact = (contact: boolean) => ({
  type: EnquiriesActionTypes.SET_TRIED_TO_CONTACT,
  payload: contact,
});

export const changeEnquiryContextEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType<Action, Action, SupplierActions | EnquiriesActionTypes>(
      SupplierActions.FETCH_SUPPLIER_SUCCESS,
      EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]: [Action, IApplicationState]) => {
      const { payload } = action;
      const {
        enquiryForm,
        enquiryForm: {
          fields: { message },
          dirty,
        },
        multipleEnquiry,
        enquirySupplier,
      } = state.enquiries;
      const supplier = payload.supplier || payload;
      const isVenue = isSupplierVenue(supplier);
      const isDirty = message !== '' && dirty;
      const enquiryContext = payload.show
        ? payload.infoProps.context
          ? payload.infoProps.context
          : payload.infoProps.method
        : 'sideForm';
      const context = getEnquiryFormContext(enquiryContext, isVenue, multipleEnquiry);

      const contextMessage = getEnquiryFormContextMessage({
        contextName: enquiryContext,
        isVenue,
        enquiryForm,
      });

      if (enquirySupplier?.id) {
        return of(
          setEnquirySupplier({ supplier: enquirySupplier }),
          changeEnquiryContext({
            context,
            contextMessage,
            isDirty,
          }),
        );
      }

      return of(changeEnquiryContext({ context, contextMessage, isDirty }), {
        type: EnquiriesActionTypes.TOGGLE_ENQUIRY_MESSAGE,
        payload: isDirty,
      });
    }),
    catchError((error) => of(appError({ error, feature: 'Enquiries' }))),
  );

export const changeEnquiryContext = ({
  context,
  contextMessage,
  isDirty,
}: {
  context: EnquiryFormContextType;
  contextMessage: string;
  isDirty: boolean;
}) => ({
  type: EnquiriesActionTypes.CHANGE_ENQUIRY_CONTEXT,
  payload: { context, contextMessage, isDirty },
});

export const fetchEnquiryConfirmationSupplier = (supplierId: string) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY_CONFIRMATION_SUPPLIER_START,
  payload: supplierId,
});

export const fetchEnquiryConfirmationSupplierSuccess =
  ({
    enquiryConfirmationSupplier,
    enquiredSuppliersCount,
  }: {
    enquiryConfirmationSupplier: TWithSupplierData<ISupplier>;
    enquiredSuppliersCount: number;
  }) =>
  ({ dispatch }: IDeps) => {
    dispatch(enquiryFormToggle({ show: false, track: false }));

    return {
      type: EnquiriesActionTypes.FETCH_ENQUIRY_CONFIRMATION_SUPPLIER_SUCCESS,
      payload: { enquiryConfirmationSupplier, enquiredSuppliersCount },
    };
  };

export const toggleEnquiryConfirmation = ({
  show,
  enquirySupplierId,
}: {
  show: boolean;
  enquirySupplierId?: string;
}) => {
  const { query } = Router;
  const asPath = getAsPathNormalized();

  // Preserve any existing URL query when toggling enquiry confirmation
  const { enquiryConfirmation, enquirySupplier, ...restQuery } = query;
  // Get path without query params
  const path = asPath.includes('?') ? asPath.slice(0, asPath.indexOf('?')) : asPath;
  // Remove parts not needed for URL
  delete restQuery.searchParams;

  const appendQuery = toUrlQuery({
    ...(show && { enquiryConfirmation: 'true' }),
    ...(show && enquirySupplierId && { enquirySupplier: enquirySupplierId }),
    ...restQuery,
  });

  const href = path + appendQuery;
  // Check if enquiry confirmation popup is already opened
  const alreadyOpened = enquiryConfirmation && enquirySupplier;

  if (show) {
    if (alreadyOpened) {
      // If opening popup and enquiry confirmation is already opened - append new enquiryConfirmation popup query and do shallow route REPLACE
      Router.replace(href, undefined, { shallow: true });
    } else {
      // If opening popup - append new enquiryConfirmation popup query and do shallow route PUSH
      Router.push(href, undefined, { shallow: true });
    }
  } else {
    Router.replace(href, undefined, { shallow: true });
  }

  return {
    type: EnquiriesActionTypes.TOGGLE_ENQUIRY_CONFIRMATION,
    payload: show,
  };
};

export const fetchEnquiryDates = () => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY_DATES,
});

export const fetchEnquiryDatesSuccess = (payload: Record<string, IEnquiryDate>) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY_DATES_SUCCESS,
  payload,
});

export const fetchEnquiryCount = (supplierId: string) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY_COUNT,
  payload: supplierId,
});

export const fetchEnquiryCountSuccess = (payload: number) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY_COUNT_SUCCESS,
  payload,
});

export const clearEnquiryCount = () => ({
  type: EnquiriesActionTypes.CLEAR_ENQUIRY_COUNT,
});

export const enquiryCountViewedAnalytics = (payload: IEnquiryCountAnalytics) => ({
  type: EnquiriesActionTypes.ENQUIRY_COUNT_VIEWED_ANALYTICS,
  payload,
});

export const enquiryCountClosedAnalytics = (payload: IEnquiryCountAnalytics) => ({
  type: EnquiriesActionTypes.ENQUIRY_COUNT_CLOSED_ANALYTICS,
  payload,
});

export const showEnquiriesUserLimitModal = (show: boolean) => ({
  type: EnquiriesActionTypes.SHOW_ENQUIRIES_USER_LIMIT_MODAL,
  payload: { show },
});

export const toggleMultipleEnquiry = (payload: boolean) => ({
  type: EnquiriesActionTypes.TOGGLE_MULTIPLE_ENQUIRY,
  payload,
});

export const openVenueConfirmationPopup = () => ({
  type: EnquiriesActionTypes.OPEN_CONFIRMATION_MODAL,
});

export const setEnquiryFormEditState = (payload: boolean) => ({
  type: EnquiriesActionTypes.ENQUIRY_FORM_SET_EDIT_STATE,
  payload,
});

export const setInfoProps = (infoProps: EnquiryFormInfoProps) => ({
  type: EnquiriesActionTypes.SET_INFO_PROPS,
  payload: infoProps,
});

export const setEnquiryHasFastResponseABTest = (payload: boolean) => ({
  type: EnquiriesActionTypes.SET_ENQUIRY_HAS_INTENT_FAST_RESPONSE_AB_TEST,
  payload,
});
